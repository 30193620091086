import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalAsset } from '../../../generated/GeneratedApiTypes';
import { DATA_ATTRIBUTE, vehicleColumns } from '../hooks/useSearch';
import Button from '@rio-cloud/rio-uikit/Button';

const tableClassNames = [
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-sticky',
    'table-head-filled',
].join(' ');

const tableColumnsOrder: (keyof ExternalAsset)[] = ['asset_name', 'vin', 'account_name', 'workshop_name'];

type Removable = {
    onRemove: (event: React.MouseEvent<HTMLElement>) => void;
};

export type VehiclesSelectedBodyProps = {
    selectedVehicles: ExternalAsset[];
} & Removable;

const VehiclesSelectedBody = ({ selectedVehicles, onRemove }: VehiclesSelectedBodyProps) => {
    const memoizedTableHeader = useMemo(() => {
        return (
            <thead>
                <tr>
                    {vehicleColumns.map((column: React.Key) => (
                        <th key={column} className="user-select-none text-capitalize" data-field={column}>
                            <FormattedMessage id={`workshopAssignment.columnLabels.${column}`} />
                        </th>
                    ))}
                    <th className="width-50" />
                </tr>
            </thead>
        );
    }, []);

    if (selectedVehicles.length === 0) {
        return (
            <NotFoundState headline={<FormattedMessage id="workshopAssignment.vehiclesSelectedDialog.notFound" />} />
        );
    }

    return (
        <table className={tableClassNames}>
            {memoizedTableHeader}
            <tbody>
                {selectedVehicles.map((vehicle) => {
                    return <VehiclesSelectedTableRow key={vehicle.asset_id} onRemove={onRemove} vehicle={vehicle} />;
                })}
            </tbody>
        </table>
    );
};

type VehiclesSelectedTableRowProps = {
    vehicle: ExternalAsset;
} & Removable;

const VehiclesSelectedTableRow = ({ vehicle, onRemove }: VehiclesSelectedTableRowProps) => {
    const buttonProps = {
        iconOnly: true,
        onClick: onRemove,
        [DATA_ATTRIBUTE]: vehicle.asset_id,
    };
    return (
        <tr>
            {tableColumnsOrder.map((column) => {
                return <td key={`${vehicle.asset_id}-${column}`}>{vehicle[column]}</td>;
            })}
            <td>
                <Button {...buttonProps}>
                    <span className="rioglyph rioglyph-trash" />
                </Button>
            </td>
        </tr>
    );
};

export default VehiclesSelectedBody;
