/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
    ExternalReassignAsset,
    ExternalReassignAssetsResponse,
    ExternalReassignWorkshop,
    Reassign,
} from '../../../generated/GeneratedApiTypes';
import { config } from '../../../config';
import { gaPush } from '../../../configuration/setup/googleAnalytics';

type RequestBody = Reassign.ReassignAssets.RequestBody;
type ResponseBody = Reassign.ReassignAssets.ResponseBody;

const validationUrl = `${config.backend.WORKSHOP_BACKEND_URI}/reassign?dry_run=true`;
const reassignmentUrl = `${config.backend.WORKSHOP_BACKEND_URI}/reassign?dry_run=false`;

const useValidationStep = (
    body: RequestBody,
    handleDryRunAssignment: (successDryRun: boolean) => void,
    handleShowReassignmentState?: () => void,
    doReassignment?: boolean,
    selectedStep?: number,
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [reloadState, setReloadState] = useState<{}>({});

    const [numberOfSuccessfullyReassignedVehicles, setNumberOfSuccessfullyReassignedVehicles] = useState<number>(0);
    const [numberOfFailedReassignedVehicles, setNumberOfFailedReassignedVehicles] = useState<number>(0);
    const [results, setResults] = useState<ExternalReassignAsset[]>([]);
    const [finalWorkshop, setFinalWorkshop] = useState<ExternalReassignWorkshop>();

    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };

    const handleGaEvent = (response: AxiosResponse<ExternalReassignAssetsResponse, any>) => {
        if (doReassignment) {
            gaPush({
                event: 'number_of_vehicles_requested',
                parameters: {
                    element_name: 'ReassignVehiclesDialog',
                    component_name: 'Button',
                    flow_name: 'reassign_vehicles',
                    trigger: 'click',
                    value: response.data.assets.length,
                },
                userProps: {},
            });
        }
    };

    useEffect(() => {
        if (!isLoading && selectedStep !== 0) {
            setIsLoading(true);
            axios
                .post<ResponseBody>(doReassignment ? reassignmentUrl : validationUrl, body)
                .then((response) => {
                    handleGaEvent(response);
                    const nbrReassignVehiclesSuccess = response.data.assets.reduce((n, val) => {
                        // @ts-ignore
                        return n + (val.status === 'ok');
                    }, 0);
                    setNumberOfSuccessfullyReassignedVehicles(nbrReassignVehiclesSuccess);

                    const failureVehicles = response.data.assets.length - nbrReassignVehiclesSuccess;
                    setNumberOfFailedReassignedVehicles(failureVehicles);

                    setResults(response.data.assets);
                    setFinalWorkshop(response.data.workshop);
                    if (doReassignment) handleShowReassignmentState!!();
                    else handleDryRunAssignment(failureVehicles !== response.data.assets.length);
                })
                .catch((error) => {
                    console.error(`Error fetching /reassign?dry_run=${doReassignment ? 'false' : 'true'}`, error);
                    setHasErrors(true);
                    if (doReassignment) handleShowReassignmentState!!();
                    else handleDryRunAssignment!!(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [reloadState, doReassignment]);

    return {
        isLoading,
        hasErrors,
        results,
        finalWorkshop,
        numberOfSuccessfullyReassignedVehicles,
        numberOfFailedReassignedVehicles,
        triggerReload,
    };
};

export default useValidationStep;
