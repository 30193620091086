import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TypeFromArray } from '../../utils/types';
import { RootState } from '../setup/store';

export const FEATURE_SLICE_NAME = 'featureToggle';

/**
 * When adding a toggle create a constant and add it to CURRENT_TOGGLES array
 */
export const CURRENT_TOGGLES = [] as const;

export type KnownToggles = TypeFromArray<typeof CURRENT_TOGGLES>;

export type EventContent = {
    featureKey: KnownToggles;
    value: string | undefined;
};

type FeatureToggleState = {
    features: {
        [key in KnownToggles]: boolean;
    };
};

const initialState: FeatureToggleState = {
    features: {},
};

const featureToggleSlice = createSlice({
    initialState,
    name: FEATURE_SLICE_NAME,
    reducers: {
        featureToggleChanged: (state: FeatureToggleState, action: PayloadAction<EventContent>) => {
            const { featureKey, value } = action.payload;
            if (CURRENT_TOGGLES.includes(featureKey)) {
                // @ts-ignore
                state.features[featureKey] = value !== undefined ? /true/i.test(value) : false;
            }
        },
    },
});

export const selectFeaturesState = (state: RootState) => state[FEATURE_SLICE_NAME];

export const isFeatureEnabled = (feature: KnownToggles) => (state: RootState) =>
    state[FEATURE_SLICE_NAME].features[feature];

export const { featureToggleChanged } = featureToggleSlice.actions;
export default featureToggleSlice.reducer;
