import { Suspense, useEffect } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import ReassignVehiclesTable from '../workshop-assignment/ReassignVehiclesTable';
import { REQUEST_ASSET_TREE_SELECTION, sendMessage } from './MessageHandler';
import { isAssetTreeSelected } from '../../utils/commonUtils';

const MainComponent = () => {
    useEffect(() => {
        if (isAssetTreeSelected()) {
            sendMessage({
                type: REQUEST_ASSET_TREE_SELECTION,
                payload: {},
            });
        }
    }, []);

    return (
        <div className={'overflow-hidden display-flex flex-column padding-20 bg-white'}>
            <Suspense fallback={<LoadingIndicator />}>
                <ReassignVehiclesTable />
            </Suspense>
        </div>
    );
};

export default MainComponent;
