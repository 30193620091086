import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { useIntl } from 'react-intl';

export type InternalErrorStateProps = {
    reloadTriggerFunction?: () => void;
    additionalClassName?: string;
    innerClassName?: string;
    fullWidth?: boolean;
};

const InternalErrorState = (props: InternalErrorStateProps) => {
    const { reloadTriggerFunction, additionalClassName, innerClassName, fullWidth } = props;
    const intl = useIntl();
    return (
        <ErrorState
            headline={intl.formatMessage({ id: 'workshopAssignment.common.error.emptyState.headline' })}
            message={intl.formatMessage({ id: 'workshopAssignment.common.error.emptyState.message' })}
            buttons={[
                {
                    text: intl.formatMessage({ id: 'workshopAssignment.common.error.emptyState.button' }),
                    onClick: reloadTriggerFunction,
                },
            ]}
            // className should keep the original values plus the news values passed
            className={`display-flex justify-content-center max-width-100pct ${additionalClassName}`}
            innerClassName={innerClassName}
            fullWidth={fullWidth}
        />
    );
};

export default InternalErrorState;
