import axios from 'axios';
import { debounce } from 'lodash';
import { config } from '../../../config';
import { useEffect, useMemo, useState } from 'react';
import { ExternalWorkshop, ExternalWorkshopResponse } from '../../../generated/GeneratedApiTypes';

const useSearchWorkshops = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [searchWorkshopNameText, setSearchWorkshopNameText] = useState<string>('');
    const [searchCityText, setSearchCityText] = useState<string>('');
    const [workshops, setWorkshops] = useState<ExternalWorkshop[]>([]);
    const [cities, setCities] = useState<string[]>([]);
    const [reloadState, setReloadState] = useState<{}>({});
    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };

    const setSearchWorkshopNameTextDebounced = useMemo(() => {
        return debounce(setSearchWorkshopNameText, 300);
    }, []);

    const setSearchCityTextDebounced = useMemo(() => {
        return debounce(setSearchCityText, 300);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const url = config.backend.BACKEND_URI + '/workshop-assignment/workshops';
        const params = {
            workshop_name: searchWorkshopNameText || '',
            city: searchCityText || '',
            limit: 20,
        };

        axios
            .get<ExternalWorkshopResponse>(url, { params })
            .then((res) => {
                const { items } = res.data;
                const workshopsFiltered = items || [];
                const citiesFiltered = uniqAndRemoveUndefined(workshopsFiltered.map((x) => x.city));
                setWorkshops(workshopsFiltered);
                setCities(citiesFiltered);
            })
            .catch((error) => {
                console.error('Error fetching search accounts endpoint', error);
                setHasErrors(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [reloadState, searchWorkshopNameText, searchCityText]);
    return {
        isLoading,
        hasErrors,
        triggerReload,
        workshops,
        cities,
        setSearchWorkshopNameTextDebounced,
        setSearchCityTextDebounced,
        searchWorkshopNameText,
        searchCityText,
    };
};

const uniqAndRemoveUndefined = (list: (string | undefined)[]): string[] => {
    return [...new Set(list)].filter((x) => x !== undefined).map((x) => x as string);
};

export default useSearchWorkshops;
