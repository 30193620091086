import axios from 'axios';
import { config } from '../../../config';
import { useEffect, useMemo, useState } from 'react';
import { ExternalAccountInfo, ExternalAccountResponse } from '../../../generated/GeneratedApiTypes';
import { debounce } from 'lodash';

const useSearchAccounts = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [searchAccountsText, setSearchAccountsText] = useState<string>();
    const [accounts, setAccounts] = useState<ExternalAccountInfo[]>([]);
    const [reloadState, setReloadState] = useState<{}>({});
    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };

    const setSearchAccountsTextDebounced = useMemo(() => {
        return debounce(setSearchAccountsText, 300);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const url = config.backend.BACKEND_URI + '/workshop-assignment/accounts/search';
        const params = {
            account_name: searchAccountsText || '',
            limit: 20,
        };

        axios
            .get<ExternalAccountResponse>(url, { params })
            .then((res) => {
                const { items } = res.data;
                setAccounts(items || []);
            })
            .catch((error) => {
                console.error('Error fetching search accounts endpoint', error);
                setHasErrors(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [reloadState, searchAccountsText]);
    return {
        isLoading,
        hasErrors,
        triggerReload,
        accounts,
        searchAccountsText,
        setSearchAccountsTextDebounced,
    };
};

export default useSearchAccounts;
