import { isTraining } from '../../config';

declare global {
    interface Window {
        dataLayer?: any[];
    }
}

export interface GAEvent {
    event: GoogleAnalyticsCustomEvents;
    parameters: GoogleAnalytics4Parameters;
    userProps: GoogleAnalytics4UserProperties;
}

export type GoogleAnalyticsCustomEvents =
    | 'click_customer_search_box'
    | 'click_city_search_box'
    | 'click_workshop_search_box'
    | 'click_vehicle_search_box'
    | 'click_asset_tree'
    | 'finish_reassign_vehicles'
    | 'number_of_reassigned_vehicles'
    | 'number_of_vehicles_requested'
    | 'close_reassign_to_modal__step-1'
    | 'close_reassign_to_modal__step-2';

export type GoogleAnalytics4Parameters = {
    element_name: string;
    component_name:
        | 'ApplicationLayout'
        | 'ApplicationHeader'
        | 'BottomSheet'
        | 'Sidebar'
        | 'MainNavigation'
        | 'SubNavigation'
        | 'AppNavigationBar'
        | 'Pager'
        | 'Button'
        | 'Checkbox'
        | 'ClearableInput'
        | 'LoadMoreButton'
        | 'NumberControl'
        | 'RadioButton '
        | 'Slider'
        | 'Switch'
        | 'ToggleButton'
        | 'Collapse'
        | 'Expander'
        | 'Fade'
        | 'AssetTree'
        | 'AutoSuggest'
        | 'ButtonDropdown'
        | 'Select'
        | 'DatePicker'
        | 'FilePicker'
        | 'TimePicker'
        | 'StatusBar'
        | 'SteppedProgress '
        | 'AnimatedNumber'
        | 'Carousel'
        | 'ContentLoader'
        | 'DataTabs'
        | 'Dialog'
        | 'NoData'
        | 'ErrorState'
        | 'NotFoundState'
        | 'EmptyState'
        | 'ForbiddenState'
        | 'MaintenanceState'
        | 'CustomState'
        | 'NotBookedState'
        | 'StatsWidget'
        | 'TableToolbar'
        | 'TableSearch'
        | 'TableViewToggles'
        | 'TableCardsSorting'
        | 'TableSettingDialog'
        | 'Teaser'
        | 'Timeline'
        | 'AreaChart'
        | 'LineChart'
        | 'BarChart'
        | 'PieChart'
        | 'RadialBarChart'
        | 'Map'
        | 'SingleMapMarker'
        | 'ClusterMapMarker'
        | 'Marker';
    trigger: 'click' | 'visibility';
    flow_name?: string;
    identification_type?: string;
    connection_type?: 'inbound' | 'outbound';
    connection_brand?: 'scania' | 'idem' | 'fleetboard' | 'volvo';
    components?: number;
    method?: string;
    search_term?: string;
    currency?: 'Eur' | 'Usd';
    value?: number;
    page_location?: string;
    page_title?: string;
    ecommerce?: object;
    workshop_id?: string;
    asset_id?: string;
    rating?: number;
    callback_requested?: 'yes' | 'no';
};

export type GoogleAnalytics4UserProperties = {
    login_method?: 'mobile' | 'email';
    account_id?: string;
    tenant?: string; // rio-eu.test, rio-eu.prod, ...
    user_id?: string;
};

export const gaPush = (gaEvent: GAEvent) => {
    const event = gaEvent.event;
    const params = gaEvent.parameters;
    const userProps = gaEvent.userProps;

    if (process.env.NODE_ENV === 'development') {
        console.debug('GA4 Event: ', gaEvent);
        return;
    }
    const ignoreGaEvents = isTraining();
    if (!ignoreGaEvents && window.dataLayer) {
        window.dataLayer.push({ event, ...params, ...userProps });
    }
};
