/* eslint-disable max-len */

import { useState } from 'react';
import { SortBy, SortDirection } from '../../../generated/GeneratedApiTypes';

export const useSorting = (defaultValue: SortBy) => {
    const [sortBy, setSortBy] = useState<SortBy>(defaultValue);
    const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.ASCENDING);

    const getSortDir = (previousSortBy: SortBy, sortByValue: SortBy) => {
        if (sortByValue === previousSortBy) {
            return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
        }
        return SortDirection.ASCENDING;
    };

    const handleSortChange = (event: { currentTarget: { getAttribute: (arg0: string) => any } }) => {
        const sortByValue = event.currentTarget.getAttribute('data-sortby');
        setSortBy(sortByValue);
        setSortDir(getSortDir(sortBy, sortByValue));
    };

    return {
        sortBy,
        sortDir,
        handleSortChange,
    };
};
