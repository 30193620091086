import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import { useIntl } from 'react-intl';
import { CITY, WORKSHOP } from '../hooks/useSearch';
import { ExternalWorkshop } from '../../../generated/GeneratedApiTypes';

type TableRowProps = {
    row: ExternalWorkshop;
    columns: string[];
    onClick: (event: any) => void;
    selectedRowId: string;
};

const ReassignVehiclesDialogTableRow = ({ row, columns, onClick, selectedRowId }: TableRowProps) => {
    const intl = useIntl();

    const getColumnValue = (col: React.Key) => {
        if (col === CITY) return row.city;
        return row.workshop_name;
    };

    return (
        <tr key={row.workshop_name} data-key={row.workshop_id} onClick={onClick}>
            <td className="table-checkbox">
                <RadioButton
                    data-testid={`radio-${row.workshop_name}`}
                    checked={selectedRowId === row.workshop_id}
                    onChange={onClick}
                />
            </td>
            {columns.map((col: React.Key) => (
                <td key={col} data-field={intl.formatMessage({ id: `workshopAssignment.columnLabels.${col}` })}>
                    {col === WORKSHOP ? (
                        <div>
                            <span className="rioglyph rioglyph-workshop margin-right-5" />
                            <span>{row.workshop_name}</span>
                        </div>
                    ) : (
                        <span>{getColumnValue(col)}</span>
                    )}
                </td>
            ))}
        </tr>
    );
};

export default ReassignVehiclesDialogTableRow;
