/* eslint-disable max-len */
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { DebouncedFunc } from 'lodash';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gaPush } from '../../../configuration/setup/googleAnalytics';
import { InputSearchTextRef } from './ReassignVehiclesDialog';

type WorkshopSelectionStepSearchProps = {
    workshopSearchText: string;
    citySearchText: string;
    setSearchWorkshopNameText: DebouncedFunc<React.Dispatch<React.SetStateAction<string>>>;
    setSearchCityText: DebouncedFunc<React.Dispatch<React.SetStateAction<string>>>;
};

const WorkshopSelectionStepSearch = ({
    workshopSearchText,
    citySearchText,
    setSearchWorkshopNameText,
    setSearchCityText,
}: WorkshopSelectionStepSearchProps) => {
    const { cityInputRef, setCityInputRef, setWorkshopInputRef, workshopInputRef, focusOn, setFocusOn } =
        useContext(InputSearchTextRef);
    return (
        <>
            <div className="width-50pct">
                <InputSearchText
                    fieldName={'workshop'}
                    focusOnSetter={setFocusOn}
                    hasFocus={focusOn === 'workshop'}
                    inputValue={workshopSearchText}
                    setDebouncedInputValue={setSearchWorkshopNameText}
                    inputRef={workshopInputRef}
                    inputRefSetter={setWorkshopInputRef}
                />
            </div>
            <div className="width-50pct margin-left-15">
                <InputSearchText
                    fieldName={'city'}
                    focusOnSetter={setFocusOn}
                    hasFocus={focusOn === 'city'}
                    inputValue={citySearchText}
                    setDebouncedInputValue={setSearchCityText}
                    inputRef={cityInputRef}
                    inputRefSetter={setCityInputRef}
                />
            </div>
        </>
    );
};

export type InputSearchTextFieldName = 'workshop' | 'city';

type InputSearchTextProps = {
    fieldName: InputSearchTextFieldName;
    focusOnSetter: ((fieldName: InputSearchTextFieldName) => void) | null;
    hasFocus: boolean;
    inputRef: HTMLInputElement | null;
    inputRefSetter: ((el: HTMLInputElement | null) => void) | null;
    inputValue: string;
    setDebouncedInputValue: DebouncedFunc<React.Dispatch<React.SetStateAction<string>>>;
};
type InputProps = {
    autoFocus?: boolean;
    placeholder: string;
    onClear: () => void;
    onFocus: (arg: any) => void;
    onChange: (value: string) => void;
};

const InputSearchText = ({
    fieldName,
    focusOnSetter,
    hasFocus,
    inputRef,
    inputRefSetter,
    inputValue = '',
    setDebouncedInputValue,
}: InputSearchTextProps) => {
    const [localInputValue, setLocalInputValue] = useState(inputValue);
    const intl = useIntl();

    const INTL_FIELD_PREFIX = `workshopAssignment.reassignVehiclesDialog.reassignTo.${fieldName}Search`;

    const inputProps: InputProps = {
        autoFocus: true,
        placeholder: intl.formatMessage({
            id: `${INTL_FIELD_PREFIX}.placeholder`,
        }),
        onClear: () => {
            focusOnSetter?.(fieldName);
            inputRef?.focus();
        },
        onFocus: (arg: any) => {
            focusOnSetter?.(fieldName);
            inputRefSetter?.(arg.target);
            gaPush({
                event: `click_${fieldName}_search_box`,
                parameters: {
                    element_name: 'WorkshopSelectionStepSearch',
                    component_name: 'ClearableInput',
                    flow_name: 'reassign_vehicles',
                    trigger: 'click',
                },
                userProps: {},
            });
        },
        onChange: (value: string) => {
            setLocalInputValue(value);
            setDebouncedInputValue(value);
        },
    };
    if (!hasFocus) {
        delete inputProps.autoFocus;
    }
    return (
        <div className="width-100pct">
            <label>
                <FormattedMessage id={`${INTL_FIELD_PREFIX}.label`} />
            </label>
            <div className={'input-group'}>
                <span className={'input-group-addon'}>
                    <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
                </span>
                <ClearableInput
                    {...inputProps}
                    data-testid={`search-by-${fieldName}`}
                    defaultValue={localInputValue || inputValue}
                    value={localInputValue}
                />
            </div>
        </div>
    );
};

export default WorkshopSelectionStepSearch;
