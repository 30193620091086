import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { useIntl } from 'react-intl';
import { CUSTOMER, VEHICLE, VIN } from './hooks/useSearch';
import { ExternalAsset } from '../../generated/GeneratedApiTypes';

type TableRowProps = {
    row: ExternalAsset;
    columns: string[];
    onClick: (event: any) => void;
};

const TableRow = ({ row, columns, onClick }: TableRowProps) => {
    const intl = useIntl();

    const getColumnValue = (col: React.Key) => {
        if (col === VIN) return row.vin;
        if (col === CUSTOMER) return row.account_name;
        return row.workshop_name;
    };

    return (
        <tr key={row.asset_name} data-key={row.asset_id} onClick={onClick}>
            <td className="table-checkbox">
                <Checkbox data-testid={`checkbox-${row.asset_name}`} />
            </td>
            {columns.map((col: React.Key) => (
                <td key={col} data-field={intl.formatMessage({ id: `workshopAssignment.columnLabels.${col}` })}>
                    {col === VEHICLE ? (
                        <div>
                            <span className="rioglyph rioglyph-truck margin-right-5" />
                            <b>{row.asset_name}</b>
                        </div>
                    ) : (
                        <span>{getColumnValue(col)}</span>
                    )}
                </td>
            ))}
        </tr>
    );
};

export default TableRow;
