import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../../config';
import { ExternalWorkshop, WorkshopAssignment } from '../../../generated/GeneratedApiTypes';
import { FetchItems } from './useSearch';

type ResponseBody = WorkshopAssignment.GetWorkshops.ResponseBody;

const useFetchWorkshops = (fetchItems: FetchItems) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [filteredWorkshops, setFilteredWorkshops] = useState<ExternalWorkshop[]>([]);
    const [reloadState, setReloadState] = useState<{}>({});
    const [selectedWorkshops, setSelectedWorkshops] = useState<ExternalWorkshop[]>([]);
    const [selectedCities, setSelectedCities] = useState<string[]>([]);
    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };

    useEffect(() => {
        if (config.backend.BACKEND_URI && fetchItems === 'fetchWorkshops') {
            setIsLoading(true);
            const url = config.backend.BACKEND_URI + '/workshop-assignment/workshops';
            const params = {
                workshop_name: selectedWorkshops.map((w) => w.workshop_name).join(','),
                city: selectedCities.join(','),
            };

            axios
                .get<ResponseBody>(url, { params })
                .then((res) => {
                    const { items } = res.data;
                    setFilteredWorkshops(items ? items : []);
                })
                .catch((error) => {
                    console.error('Error fetching filtered workshops endpoint', error);
                    setHasErrors(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [reloadState, selectedWorkshops, selectedCities]);
    return {
        isLoading,
        hasErrors,
        filteredWorkshops,
        triggerReload,
        selectedWorkshops,
        setSelectedWorkshops,
        selectedCities,
        setSelectedCities,
    };
};

export default useFetchWorkshops;
