import React, { useEffect, useMemo, useState } from 'react';
import { ExternalReassignAsset, ExternalWorkshop, ExternalAsset, Reassign } from '../../../generated/GeneratedApiTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import InternalErrorState from '../../app/InternalErrorState';
import useValidationStep from '../hooks/useValidationStep';
import ReassignVehiclesOverviewTable from './ReassignVehiclesOverviewTable';

type RequestBody = Reassign.ReassignAssets.RequestBody;

const defaultNbrElementsVisible = 4;
const CLASS_TEXT_SIZE_14 = 'text-size-14';

type ReassignValidationStepProps = {
    selectedWorkshop: ExternalWorkshop;
    selectedVehicles: ExternalAsset[];
    handleAfterDryRunAssignment: (successDryRun: boolean) => void;
};

const ReassignValidationStep = ({
    selectedWorkshop,
    selectedVehicles,
    handleAfterDryRunAssignment,
}: ReassignValidationStepProps) => {
    const intl = useIntl();
    const [visibleRows, setVisibleRows] = useState<ExternalReassignAsset[]>([]);
    const [showMore, setShowMore] = useState<boolean>(false);

    const body: RequestBody = {
        workshop_id: selectedWorkshop.workshop_id,
        assets_id: selectedVehicles.map((vehicle) => vehicle.asset_id),
    };

    const {
        isLoading,
        hasErrors,
        results,
        finalWorkshop,
        numberOfSuccessfullyReassignedVehicles,
        numberOfFailedReassignedVehicles,
        triggerReload,
    } = useValidationStep(body, handleAfterDryRunAssignment);
    useEffect(() => {
        setVisibleRows(results.slice(0, defaultNbrElementsVisible));
    }, [results]);

    const changeTotalNbrRowsVisible = () => {
        if (!showMore) {
            setVisibleRows(results);
        } else {
            setVisibleRows(results.slice(0, defaultNbrElementsVisible));
        }

        setShowMore(!showMore);
    };

    const tableEffectClassNames = showMore
        ? classNames('position-relative margin-bottom-10 margin-top-10 scroll-effect-table')
        : classNames('position-relative margin-bottom-10 margin-top-10');

    const memoizedWorkshopWrapper = useMemo(() => {
        return (
            <div data-testid="new-workshop-wrapper">
                <h5 className="margin-bottom-20 text-bold text-capitalize">
                    <FormattedMessage id="workshopAssignment.reassignVehiclesDialog.newWorkshop" />
                </h5>
                <div>
                    <span className={CLASS_TEXT_SIZE_14}> {finalWorkshop?.workshop_name} </span>
                </div>
                <div>
                    <span className={CLASS_TEXT_SIZE_14}> {finalWorkshop?.address?.street} </span>
                </div>
                <div>
                    <span className={CLASS_TEXT_SIZE_14}> {finalWorkshop?.address?.zip} </span>
                    <span className={CLASS_TEXT_SIZE_14}> {finalWorkshop?.address?.city}</span>
                </div>
                <div>
                    <span className={CLASS_TEXT_SIZE_14}> {finalWorkshop?.address?.country} </span>
                </div>
            </div>
        );
    }, [finalWorkshop]);

    if (isLoading) {
        return (
            <Spinner
                data-testid={'validation-spinner'}
                show
                text={
                    // eslint-disable-next-line max-len
                    intl.formatMessage({ id: 'workshopAssignment.reassignVehiclesDialog.validatingVehiclesRequest' })
                }
                isFullSized
            />
        );
    } else if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReload} />;
    } else {
        return (
            <>
                <div className={'margin-y-10'}>
                    <span className="text-bold text-size-20">
                        <FormattedMessage
                            id="workshopAssignment.reassignVehiclesDialog.vehiclesToReassign"
                            values={{
                                nbr: numberOfSuccessfullyReassignedVehicles,
                            }}
                        />
                    </span>
                </div>
                {numberOfFailedReassignedVehicles > 0 && (
                    <div className={'display-flex align-items-center text-size-14'}>
                        <span className="rioglyph rioglyph-info-sign text-color-danger margin-right-10" />
                        <span className={'text-color-danger'}>
                            <FormattedMessage
                                id="workshopAssignment.reassignVehiclesDialog.vehiclesCantBeReassign"
                                values={{
                                    nbr: numberOfFailedReassignedVehicles,
                                }}
                            />
                        </span>
                    </div>
                )}

                <ReassignVehiclesOverviewTable rows={visibleRows} showMore={showMore} />

                <div className={tableEffectClassNames}>
                    <div className="display-flex flex-column justify-content-center align-items-center">
                        {results.length > 4 && (
                            <button
                                data-testid="show-more-less-button"
                                type="button"
                                className="btn btn-muted custom-mute-button"
                                onClick={changeTotalNbrRowsVisible}
                            >
                                {showMore ? (
                                    <FormattedMessage id="workshopAssignment.reassignVehiclesDialog.showLess" />
                                ) : (
                                    <FormattedMessage id="workshopAssignment.reassignVehiclesDialog.showMore" />
                                )}
                            </button>
                        )}
                    </div>
                </div>
                {memoizedWorkshopWrapper}
            </>
        );
    }
};

export default ReassignValidationStep;
