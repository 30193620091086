/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';

import { useSearch } from './hooks/useSearch';
import useSearchAccounts from './hooks/useSearchAccounts';
import { ExternalAccountInfo, ExternalSearchAsset } from '../../generated/GeneratedApiTypes';
import useSearchAssets from './hooks/useSearchAssets';
import { gaPush } from '../../configuration/setup/googleAnalytics';
type ReassignVehiclesSearchProps = {
    useSearchHook: ReturnType<typeof useSearch>;
};

const ReassignVehiclesSearch = ({ useSearchHook }: ReassignVehiclesSearchProps) => {
    const intl = useIntl();
    const {
        searchCustomer,
        searchVehicle,
        selectedCustomers,
        selectedVehicles,
        currentCustomerSuggestions,
        currentVehicleSuggestions,
        setCurrentCustomerSuggestions,
        setCurrentVehicleSuggestions,
        handleCustomerSuggestionSelected,
        handleCustomerSuggestionsChange,
        handleVehicleSuggestionSelected,
        handleVehicleSuggestionsChange,
        handleRemoveSelectedCustomer,
        handleRemoveSelectedVehicle,
        handleDeleteAllCustomerFilters,
        handleDeleteAllVehicleFilters,
    } = useSearchHook;
    const { accounts, setSearchAccountsTextDebounced } = useSearchAccounts();
    const { assets, setSearchAssetsTextDebounced } = useSearchAssets();

    useEffect(() => {
        const filteredAssets = assets.filter((x) => !selectedVehicles.some((y) => x.asset_id === y.asset_id));
        const filteredAccounts = accounts.filter((x) => !selectedCustomers.some((y) => x.account_id === y.account_id));
        setCurrentCustomerSuggestions(filteredAccounts);
        setCurrentVehicleSuggestions(filteredAssets);
    }, [selectedCustomers, accounts, assets]);

    const inputPropsCustomer = {
        placeholder: intl.formatMessage({
            id: 'workshopAssignment.customerSearch.placeholder',
        }),
        icon: 'rioglyph-search',
        value: searchCustomer,
        onClear: () => {},
        onFocus: () => {
            gaPush({
                event: 'click_customer_search_box',
                parameters: {
                    element_name: 'ReassignVehiclesSearch',
                    component_name: 'AutoSuggest',
                    flow_name: 'reassign_vehicles',
                    trigger: 'click',
                },
                userProps: {},
            });
        },
    };

    const inputPropsVehicle = {
        placeholder: intl.formatMessage({
            id: 'workshopAssignment.vehicleSearch.placeholder',
        }),
        icon: 'rioglyph-search',
        value: searchVehicle,
        onClear: () => {},
        onFocus: () => {
            gaPush({
                event: 'click_vehicle_search_box',
                parameters: {
                    element_name: 'ReassignVehiclesSearch',
                    component_name: 'AutoSuggest',
                    flow_name: 'reassign_vehicles',
                    trigger: 'click',
                },
                userProps: {},
            });
        },
    };

    const renderCustomerSuggestion = (suggestion: any) => (
        <div data-testid={`suggestion-customer-${suggestion.account_id}`}>
            <span className="rioglyph rioglyph-user margin-right-5" />
            <span>{suggestion.account_name}</span>
        </div>
    );

    const renderVehicleSuggestion = (suggestion: any) =>
        suggestion && (
            <div data-testid={`suggestion-${suggestion.asset_id}`}>
                <span className="rioglyph rioglyph-truck margin-right-5" />
                {suggestion.asset_name.toString().toLowerCase().includes(searchVehicle.toLowerCase()) ? (
                    <span>
                        <b>{suggestion.asset_name}</b> | {suggestion.vin}
                    </span>
                ) : (
                    <span>
                        {suggestion.asset_name} | <b>{suggestion.vin}</b>
                    </span>
                )}
            </div>
        );

    const handleSearchCustomerSuggestionsChange = (argument: { value: string }) => {
        handleCustomerSuggestionsChange(argument);
        setSearchAccountsTextDebounced(argument.value);
    };

    const handleSearchVehicleSuggestionsChange = (argument: { value: string }) => {
        handleVehicleSuggestionsChange(argument);
        setSearchAssetsTextDebounced(argument.value);
    };

    return (
        <>
            <div className="width-50pct">
                <div className="width-100pct">
                    <label>
                        <FormattedMessage id="workshopAssignment.customerSearch.label" />
                    </label>
                    <AutoSuggest
                        inputProps={inputPropsCustomer}
                        suggestions={currentCustomerSuggestions}
                        noItemMessage={intl.formatMessage({ id: 'workshopAssignment.common.searchBar.notFound' })}
                        onSuggestionSelected={handleCustomerSuggestionSelected}
                        onSuggestionsFetchRequested={handleSearchCustomerSuggestionsChange}
                        renderSuggestion={renderCustomerSuggestion}
                        openOnFocus
                    />
                    <TagList className="margin-top-15">
                        {selectedCustomers.map((item: ExternalAccountInfo) => (
                            <Tag
                                key={item.account_id}
                                data-testid={`tag-customer-${item.account_id}`}
                                className="bg-lightest"
                                size="small"
                                round={false}
                                deletable
                                onClick={() => handleRemoveSelectedCustomer(item)}
                            >
                                {item.account_name}
                            </Tag>
                        ))}
                    </TagList>
                    {selectedCustomers.length > 1 && (
                        <div
                            className="btn btn-link btn-xs"
                            data-testid={'delete-all-customer-filters'}
                            onClick={handleDeleteAllCustomerFilters}
                        >
                            <span className="rioglyph rioglyph-trash text-size-12" />
                            <FormattedMessage id="workshopAssignment.common.deleteAllFilters" />
                        </div>
                    )}
                </div>
            </div>
            <div className="width-50pct margin-left-15">
                <div className="width-100pct">
                    <label>
                        <FormattedMessage id="workshopAssignment.vehicleSearch.label" />
                    </label>
                    <AutoSuggest
                        inputProps={inputPropsVehicle}
                        suggestions={currentVehicleSuggestions}
                        noItemMessage={intl.formatMessage({ id: 'workshopAssignment.common.searchBar.notFound' })}
                        onSuggestionSelected={handleVehicleSuggestionSelected}
                        onSuggestionsFetchRequested={handleSearchVehicleSuggestionsChange}
                        renderSuggestion={renderVehicleSuggestion}
                        openOnFocus
                    />
                    <TagList className="margin-top-15">
                        {selectedVehicles.map((item: ExternalSearchAsset) => (
                            <Tag
                                key={item.asset_id}
                                data-testid={`tag-vehicle-${item.asset_id}`}
                                className="bg-lightest"
                                size="small"
                                round={false}
                                deletable
                                onClick={() => handleRemoveSelectedVehicle(item)}
                            >
                                {item.asset_name} | {item.vin}
                            </Tag>
                        ))}
                    </TagList>
                    {selectedVehicles.length > 1 && (
                        <div
                            className="btn btn-link btn-xs"
                            data-testid={'delete-all-vehicle-filters'}
                            onClick={handleDeleteAllVehicleFilters}
                        >
                            <span className="rioglyph rioglyph-trash text-size-12" />
                            <FormattedMessage id="workshopAssignment.common.deleteAllFilters" />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ReassignVehiclesSearch;
