import { ExternalReassignAsset } from '../../../generated/GeneratedApiTypes';
import classNames from 'classnames';
import { vehicleColumns, VEHICLE } from '../hooks/useSearch';
import { FormattedMessage } from 'react-intl';
import ReassignVehiclesDialogOverviewTableRow from './ReassignVehiclesDialogOverviewTableRow';
import React, { useMemo } from 'react';

type ReassignVehiclesOverviewTableProps = {
    rows: ExternalReassignAsset[];
    showMore: boolean;
};

// eslint-disable-next-line sonarjs/no-duplicate-string
const middleColumnsClassNames = classNames('border-none', 'bg-white', 'text-color-dark', 'text-capitalize');
const firstColumnClassNames = classNames('border-none', 'bg-white', 'text-color-gray', 'padding-0');
const dropdownActionColumnClassNames = classNames('border-none', 'bg-white', 'text-color-gray', 'table-action');

const ReassignVehiclesOverviewTable = ({ rows, showMore }: ReassignVehiclesOverviewTableProps) => {
    const memoizedTableHead = useMemo(() => {
        return (
            <thead>
                <tr>
                    <th className={firstColumnClassNames} />
                    {vehicleColumns.map((col: React.Key) => (
                        <th
                            key={col}
                            className={middleColumnsClassNames}
                            style={{ padding: `${col === VEHICLE ? '7px 7px' : ''} ` }}
                        >
                            <span>
                                <FormattedMessage id={`workshopAssignment.columnLabels.${col}`} />
                            </span>
                        </th>
                    ))}
                    <th className={dropdownActionColumnClassNames} />
                </tr>
            </thead>
        );
    }, [rows]);

    const tableClassNames = showMore
        ? classNames('table', 'table-head-filled', 'table-condensed', 'table-sticky-in-container')
        : classNames('table', 'table-head-filled', 'table-condensed');

    const tableContainerClassNames = showMore
        ? classNames('table-responsive', 'margin-top-10', 'height-350', 'overflow-y-scroll')
        : classNames('table-responsive', 'margin-top-10');

    return (
        <div className={tableContainerClassNames}>
            <table data-testid="reassign-table" className={tableClassNames}>
                {memoizedTableHead}
                <tbody data-testid={'reassign-vehicles-table-body-dialog'}>
                    {rows.map((row: ExternalReassignAsset, index) => {
                        return (
                            <ReassignVehiclesDialogOverviewTableRow
                                key={`workshop-selection-${row.asset_id}`}
                                row={row}
                                index={index}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ReassignVehiclesOverviewTable;
