import { useState } from 'react';
import { gaPush } from '../../../configuration/setup/googleAnalytics';

export const useReassignVehiclesDialog = (triggerReloadVehiclesCallback: () => void) => {
    const [reassignVehiclesDialog, setReassignVehiclesDialog] = useState<boolean>(false);
    const [doReassignment, setDoReassignment] = useState<boolean>(false);
    const [showReassignmentState, setShowReassignmentState] = useState<boolean>(false);
    const [selectedStep, setSelectedStep] = useState<number>(0);

    const openReassignVehiclesDialog = () => {
        setReassignVehiclesDialog(true);
    };

    const closeReassignVehiclesDialog = (hasErrors: boolean) => {
        if (!hasErrors) {
            setSelectedStep(0);
        }
        setReassignVehiclesDialog(false);
        setDoReassignment(false);
        setShowReassignmentState(false);
        gaPush({
            event: selectedStep === 0 ? 'close_reassign_to_modal__step-1' : 'close_reassign_to_modal__step-2',
            parameters: {
                element_name: 'ReassignVehiclesDialog',
                component_name: 'Button',
                flow_name: 'reassign_vehicles',
                trigger: 'click',
            },
            userProps: {},
        });
        triggerReloadVehiclesCallback();
    };

    const handleDoReassignment = () => {
        setDoReassignment(true);
        gaPush({
            event: 'finish_reassign_vehicles',
            parameters: {
                element_name: 'ReassignVehiclesDialog',
                component_name: 'Button',
                flow_name: 'reassign_vehicles',
                trigger: 'click',
            },
            userProps: {},
        });
    };
    const handleShowReassignmentState = () => setShowReassignmentState(true);

    const handlePageNext = () => setSelectedStep(selectedStep + 1);

    const handleStepChange = (selectedKey: any) => setSelectedStep(selectedKey);

    return {
        reassignVehiclesDialog,
        doReassignment,
        showReassignmentState,
        selectedStep,
        openReassignVehiclesDialog,
        closeReassignVehiclesDialog,
        handleDoReassignment,
        handleShowReassignmentState,
        handlePageNext,
        handleStepChange,
    };
};
