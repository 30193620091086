import axios from 'axios';
import { config } from '../../../config';
import { useEffect, useMemo, useState } from 'react';
import { ExternalSearchAsset, ExternalSearchAssetResponse } from '../../../generated/GeneratedApiTypes';
import { debounce } from 'lodash';

const useSearchAssets = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [searchAssetsText, setSearchAssetsText] = useState<string>();
    const [assets, setAssets] = useState<ExternalSearchAsset[]>([]);
    const [reloadState, setReloadState] = useState<{}>({});
    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };

    const setSearchAssetsTextDebounced = useMemo(() => {
        return debounce(setSearchAssetsText, 300);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const url = config.backend.BACKEND_URI + '/workshop-assignment/assets/search';
        const params = {
            q: searchAssetsText || '',
            limit: 20,
        };

        axios
            .get<ExternalSearchAssetResponse>(url, { params })
            .then((res) => {
                const { items } = res.data;
                setAssets(items || []);
            })
            .catch((error) => {
                console.error('Error fetching assets search endpoint', error);
                setHasErrors(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [reloadState, searchAssetsText]);
    return {
        isLoading,
        hasErrors,
        triggerReload,
        assets,
        searchAssetsText,
        setSearchAssetsTextDebounced,
    };
};

export default useSearchAssets;
