import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { config } from '../../../config';
import {
    ExternalAsset,
    SortBy,
    WorkshopAssignment,
    SortDirection,
    ExternalAccountInfo,
    ExternalSearchAsset,
} from '../../../generated/GeneratedApiTypes';
import { useSorting } from './useSorting';
import { FetchItems } from './useSearch';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getAssetTreeWorkshopIds } from '../../app/assetTreeSlice';

type RequestBody = WorkshopAssignment.GetFilteredAssets.RequestBody;
type ResponseBody = WorkshopAssignment.GetFilteredAssets.ResponseBody;

const url = config.backend.BACKEND_URI + '/workshop-assignment/assets/filter';

const useFetchVehicles = (fetchItems: FetchItems) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [filteredAssets, setFilteredAssets] = useState<ExternalAsset[]>([]);
    const assetTreeWorkshopIds = useAppSelector(getAssetTreeWorkshopIds);
    const [selectedCustomers, setSelectedCustomers] = useState<ExternalAccountInfo[]>([]);
    const [selectedVehicles, setSelectedVehicles] = useState<ExternalSearchAsset[]>([]);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalAssets, setTotalAssets] = useState<number>(0);
    const [reloadState, setReloadState] = useState<Symbol>(Symbol());
    const { sortBy, sortDir, handleSortChange } = useSorting(SortBy.ASSET_NAME);

    const triggerReload = useCallback(() => {
        setHasErrors(false);
        setReloadState(Symbol());
        setIsLoading(true);
    }, []);

    const handlePageChange = useCallback((page: number) => {
        setPageNumber(page);
        triggerReload();
    }, []);

    useEffect(() => {
        if (config.backend.BACKEND_URI && fetchItems === 'fetchVehicles') {
            setIsLoading(true);
            const body: RequestBody = {
                sort_by: sortBy,
                sort_direction: sortDir,
                page_number: pageNumber,
                workshop_ids: assetTreeWorkshopIds,
                account_names: selectedCustomers.map((x) => x.account_name),
                vins: selectedVehicles.map((x) => x.vin),
            };

            axios
                .post<ResponseBody>(url, body)
                .then((res) => {
                    const { items, total_pages, total_items } = res.data;
                    setTotalAssets(total_items);
                    setTotalPages(total_pages);
                    setFilteredAssets(items ? items : []);
                    setHasErrors(false);
                })
                .catch((error) => {
                    console.error('Error fetching filtered assets endpoint', error);
                    setHasErrors(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [reloadState, assetTreeWorkshopIds, selectedCustomers, selectedVehicles, sortBy, sortDir]);
    return {
        isLoading,
        hasErrors,
        filteredAssets,
        paginationData: {
            pageNumber,
            totalPages,
            totalAssets,
            handlePageChange,
        },
        triggerReload,
        sortingData: {
            sortBy,
            sortDir,
            handleSortChange,
        },
        selectedCustomers,
        setSelectedCustomers,
        selectedVehicles,
        setSelectedVehicles,
    };
};

export type UseFetchVehicles = ReturnType<typeof useFetchVehicles>;

export type FetchVehicleSortingData = {
    sortBy: SortBy;
    sortDir: SortDirection;
    handleSortChange: (event: {
        currentTarget: {
            getAttribute: (arg0: string) => any;
        };
    }) => void;
};

export type FetchVehiclePaginationData = {
    pageNumber: number;
    totalPages: number;
    totalAssets: number;
    handlePageChange: (goToPage: number) => void;
};

export default useFetchVehicles;
