import { useState } from 'react';
import { ExternalReassignAsset } from '../../../generated/GeneratedApiTypes';
import { FormattedMessage } from 'react-intl';

type TableRowProps = {
    row: ExternalReassignAsset;
    index: number;
};

const BORDER_TOP = 'border-top';

const setBorderTopNone = () => {
    return (el: HTMLTableDataCellElement | null) => {
        if (el) {
            el.style.setProperty(BORDER_TOP, 'none', 'important');
        }
    };
};

const ReassignVehiclesDialogTableRow = ({ row, index }: TableRowProps) => {
    const [expandedRows, setExpandedRows] = useState(['']);

    const handleToggleRow = (assetId: string) => {
        if (expandedRows.includes(assetId)) {
            setExpandedRows(expandedRows.filter((expanded) => expanded !== assetId));
        } else {
            setExpandedRows([...expandedRows, assetId]);
        }
    };

    const setCellBorderTop = () => {
        return (el: HTMLTableDataCellElement | null) => {
            if (el && index !== 0) {
                // rest of the rows
                el.style.setProperty(BORDER_TOP, '1px solid black', 'important');
            } else if (el && index === 0) {
                // only applied to the top row
                el.style.setProperty(BORDER_TOP, 'none', 'important');
            }
        };
    };

    const hasError = (status: string) => status !== 'ok';

    const isExpanded = expandedRows.includes(row.asset_id);

    const getErrorTranslationKey = (status: string) => {
        switch (status) {
            case 'asset_type_not_supported_by_workshop':
                return 'workshopAssignment.reassignVehiclesDialog.vehiclesCantBeReassign.assetNotSupportedByWorkshop';
            case 'asset_deleted':
                return 'workshopAssignment.reassignVehiclesDialog.vehiclesCantBeReassign.assetDeleted';
            case 'asset_without_assignment':
                return 'workshopAssignment.reassignVehiclesDialog.vehiclesCantBeReassign.assetWithoutAssignment';
            case 'account_not_found':
                return 'workshopAssignment.reassignVehiclesDialog.vehiclesCantBeReassign.accountNotFound';
            default:
                return 'workshopAssignment.reassignVehiclesDialog.vehiclesCantBeReassign.unknownError';
        }
    };

    return (
        <>
            <tr key={`tr-overview-${row.asset_id}`} data-key={row.asset_id} className={'compactRow'}>
                <td key={`overview-${row.asset_id}-error`} className={'padding-0'} ref={setCellBorderTop()}>
                    <div>
                        {/* eslint-disable-next-line max-len */}
                        <span
                            className={`rioglyph rioglyph-info-sign text-color-danger ${
                                hasError(row.status) ? '' : 'invisible'
                            }`}
                        />
                    </div>
                </td>
                <td key={`overview-${row.name}`} style={{ padding: '7px' }} ref={setCellBorderTop()}>
                    <div>
                        <span>{row.name}</span>
                    </div>
                </td>
                <td key={`overview-${row.vin}`} ref={setCellBorderTop()}>
                    <div>
                        <span>{row.vin}</span>
                    </div>
                </td>
                <td key={`overview-${row.customer_name}`} ref={setCellBorderTop()}>
                    <div>
                        <span>{row.customer_name}</span>
                    </div>
                </td>
                <td key={`overview-${row.workshop_name}`} ref={setCellBorderTop()}>
                    <div>
                        <span>{row.workshop_name}</span>
                    </div>
                </td>
                <td className="table-action" onClick={() => handleToggleRow(row.asset_id)} ref={setCellBorderTop()}>
                    {hasError(row.status) && (
                        <span>
                            <span className="btn btn-muted btn-icon-only">
                                <span
                                    className={`rioglyph ${
                                        isExpanded ? 'rioglyph-chevron-up' : 'rioglyph-chevron-down'
                                    }`}
                                />
                            </span>
                        </span>
                    )}
                </td>
            </tr>
            {isExpanded && (
                <tr className="extendedRow">
                    <td className={'bg-white padding-0'} ref={setBorderTopNone()} />
                    <td colSpan={5} className={'bg-white'} style={{ padding: '7px' }} ref={setBorderTopNone()}>
                        <div className={'display-flex justify-content-start margin-y-5'}>
                            <span className={'text-bold'}>
                                <FormattedMessage id="workshopAssignment.reassignVehiclesDialog.reason" />
                                &nbsp;
                            </span>
                            <FormattedMessage id={getErrorTranslationKey(row.status)} />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default ReassignVehiclesDialogTableRow;
