import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';
import VehiclesSelectedBody, { VehiclesSelectedBodyProps } from './VehiclesSelectedBody';

type VehiclesSelectedDialogProps = {
    showDialog: boolean;
    onClose: () => void;
} & VehiclesSelectedBodyProps;

const VehiclesSelectedDialog = ({ onClose, onRemove, selectedVehicles, showDialog }: VehiclesSelectedDialogProps) => {
    return (
        <Dialog
            body={<VehiclesSelectedBody onRemove={onRemove} selectedVehicles={selectedVehicles} />}
            footer={<VehiclesSelectedFooter onClick={onClose} />}
            onHide={onClose}
            show={showDialog}
            showCloseButton
            title={<FormattedMessage id="workshopAssignment.vehiclesSelectedDialog.title" />}
            useOverflow
        />
    );
};

type VehiclesSelectedFooterProps = {
    onClick: () => void;
};

const VehiclesSelectedFooter = ({ onClick }: VehiclesSelectedFooterProps) => {
    return (
        <span>
            <button type="button" className="btn btn-primary" onClick={onClick}>
                <FormattedMessage id="workshopAssignment.vehiclesSelectedDialog.footer" />
            </button>
        </span>
    );
};

export default VehiclesSelectedDialog;
