import React, { useCallback, useState } from 'react';

export const useBasicDialogActions = () => {
    const [isDialogVisible, setShowDialog] = useState(false);

    const toggleDialog = useCallback(() => {
        setShowDialog(!isDialogVisible);
    }, [isDialogVisible]);

    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    const openDialog = useCallback(() => {
        setShowDialog(true);
    }, []);

    return {
        isDialogVisible,
        closeDialog,
        openDialog,
        toggleDialog,
    };
};
