/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type Example = string;

/** Request with filters that should be used to get assets. */
export interface ExternalFilteredAssetsRequest {
    /** The vins to filter. */
    vins?: string[];
    /** The account names to filter. */
    account_names?: string[];
    /** The workshop ids to filter. */
    workshop_ids?: string[];
    /** The column sorting direction should be applied */
    sort_by: SortBy;
    /** The sort direction that should be applied in the request */
    sort_direction: SortDirection;
    /**
     * The requested page number starting from 1
     * @format int32
     * @min 1
     */
    page_number: number;
}

/** Response with filtered assets list. */
export interface ExternalFilteredAssetsResponse {
    /** The number of the current page. */
    page_number: number;
    /** The number of items on the current page. */
    page_size: number;
    /** The total number of pages. */
    total_pages: number;
    /** The total number of items. */
    total_items: number;
    /** List containing the ExternalAsset. */
    items: ExternalAsset[];
}

export interface ExternalAsset {
    /** The asset id. */
    asset_id: string;
    /** The asset name. */
    asset_name: string;
    /** The asset vin. */
    vin: string;
    /** The account name. */
    account_name?: string;
    /** The workshop name. */
    workshop_name?: string;
}

/** Response with the assets list that match search query. */
export interface ExternalSearchAssetResponse {
    items: ExternalSearchAsset[];
}

export interface ExternalSearchAsset {
    /** The asset id. */
    asset_id: string;
    /** The asset type [Truck, Van, Bus]. */
    asset_type: string;
    /** The asset name. */
    asset_name: string;
    /** The asset vin. */
    vin: string;
}

/** Response with all or filtered workshops list. */
export interface ExternalWorkshopResponse {
    items?: ExternalWorkshop[];
}

export interface ExternalWorkshop {
    /** The workshop name. */
    workshop_name?: string;
    /** The workshop id. */
    workshop_id: string;
    /** The city of the workshop. */
    city?: string;
}

/** Resulting list of accounts. */
export interface ExternalAccountResponse {
    items?: ExternalAccountInfo[];
}

export interface ExternalAccountInfo {
    /** The account name. */
    account_name: string;
    /** The account id. */
    account_id: string;
}

/** The sort direction that should be applied in the request */
export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

/**
 * The column sorting direction should be applied
 * @example "vin"
 */
export enum SortBy {
    ACCOUNT_NAME = 'account_name',
    ASSET_NAME = 'asset_name',
    VIN = 'vin',
    WORKSHOP_NAME = 'workshop_name',
}

/** The request to perform a reassignment of a list of assets to a workshop */
export interface ExternalReassignAssetsRequest {
    /** The id of the workshop to which the reassignment should be perform */
    workshop_id: string;
    assets_id: string[];
}

/** The result from a reassignment of a list of assets to a workshop */
export interface ExternalReassignAssetsResponse {
    assets: ExternalReassignAsset[];
    /** Workshop to which assets were reassigned */
    workshop: ExternalReassignWorkshop;
    dry_run: boolean;
}

/** Assets to reassign to workshop and reassign operation status */
export interface ExternalReassignAsset {
    asset_id: string;
    name?: string;
    vin?: string;
    customer_name?: string;
    workshop_name?: string;
    status:
        | 'ok'
        | 'asset_type_not_supported_by_workshop'
        | 'asset_deleted'
        | 'asset_without_assignment'
        | 'account_not_found'
        | 'unknown_error';
}

/** Workshop to which assets were reassigned */
export interface ExternalReassignWorkshop {
    workshop_id?: string;
    workshop_name?: string;
    /** Address from Workshop to which assets were reassigned */
    address?: ExternalReassignWorkshopAddress;
}

/** Address from Workshop to which assets were reassigned */
export interface ExternalReassignWorkshopAddress {
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
}

export namespace WorkshopAssignmentExamples {
    /**
     * No description
     * @tags examples
     * @name Examples
     * @summary Example endpoint
     * @request GET:/workshop-assignment-examples
     * @secure
     * @response `200` `Example` Success
     * @response `default` `any`
     */
    export namespace Examples {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = Example;
    }
}

export namespace WorkshopAssignment {
    /**
     * No description
     * @tags assets
     * @name GetFilteredAssets
     * @summary Filtered assets.
     * @request POST:/workshop-assignment/assets/filter
     * @response `200` `ExternalFilteredAssetsResponse` Success
     * @response `400` `any` Bad Request
     * @response `403` `any` Forbidden
     * @response `default` `any`
     */
    export namespace GetFilteredAssets {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ExternalFilteredAssetsRequest;
        export type RequestHeaders = {};
        export type ResponseBody = ExternalFilteredAssetsResponse;
    }
    /**
     * No description
     * @tags assets
     * @name SearchAssets
     * @summary Search assets.
     * @request GET:/workshop-assignment/assets/search
     * @response `200` `ExternalSearchAssetResponse` Success
     * @response `400` `any` Bad Request
     * @response `403` `any` Forbidden
     * @response `default` `any`
     */
    export namespace SearchAssets {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Text to perform a search by asset name and / or asset vin. */
            q: string;
            /** The max number of assets to be returned. */
            limit?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ExternalSearchAssetResponse;
    }
    /**
     * No description
     * @tags workshops
     * @name GetWorkshops
     * @summary Gets all workshops in country with or without filters.
     * @request GET:/workshop-assignment/workshops
     * @response `200` `ExternalWorkshopResponse` Success
     * @response `400` `any` Bad Request
     * @response `403` `any` Forbidden
     * @response `default` `any`
     */
    export namespace GetWorkshops {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The workshop name or first letters of a workshop name. */
            workshop_name?: string;
            /** The city of the workshop or first letters of it. */
            city?: string;
            /** The max number of workshops to be returned. */
            limit?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ExternalWorkshopResponse;
    }
    /**
     * No description
     * @tags accounts
     * @name GetAccountsByName
     * @summary Gets account info.
     * @request GET:/workshop-assignment/accounts/search
     * @response `200` `ExternalAccountResponse` Success
     * @response `400` `any` Bad Request
     * @response `403` `any` Forbidden
     * @response `default` `any`
     */
    export namespace GetAccountsByName {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The account name or a part of it to search accounts by. */
            account_name: string;
            /** The max number of accounts to be returned. */
            limit?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ExternalAccountResponse;
    }
}

export namespace Reassign {
    /**
     * No description
     * @tags workshop
     * @name ReassignAssets
     * @summary Reassign a list of assets to a workshop
     * @request PUT:/reassign
     * @response `200` `ExternalReassignAssetsResponse` Success
     * @response `400` `any` Bad Request
     * @response `403` `any` Forbidden
     * @response `default` `any`
     */
    export namespace ReassignAssets {
        export type RequestParams = {};
        export type RequestQuery = {
            /** If set to true or omitted, the actual reassignment is not persisted */
            dry_run?: boolean;
        };
        export type RequestBody = ExternalReassignAssetsRequest;
        export type RequestHeaders = {
            /**
             * token
             * @example "<YOUR_JWT>"
             */
            Authorization?: string;
        };
        export type ResponseBody = ExternalReassignAssetsResponse;
    }
}
