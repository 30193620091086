import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { featureToggleChanged } from '../../configuration/featureToggle/featureToggleSlice';
import { gaPush } from '../../configuration/setup/googleAnalytics';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { RootState } from '../../configuration/setup/store';
import { isReadinessNotifiedSelector, readinessNotified } from './appSlice';
import { assetTreeSelectionChanged } from './assetTreeSlice';
import axios from 'axios';

// Events received from web-workshop
export const TOKEN_RENEWED = 'WEB_WORKSHOP/TOKEN_RENEWED';
export const ASSET_TREE_SELECTION_CHANGED = 'WEB_WORKSHOP/ASSET_TREE_SELECTION_CHANGED';
export const IN_MSG_FEATURE_TOGGLE_CHANGED = 'WEB_WORKSHOP/FEATURE_TOGGLE_CHANGED';

// Events sent from workshop-assignment
export const REQUEST_ASSET_TREE_SELECTION = 'WORKSHOP_ASSIGNMENT/REQUEST_ASSET_TREE_SELECTION';
export const OUT_NOTIFY_READINESS = 'MFE/BOOTSTRAP/MFE_IS_LOADED';

interface MessageEvent<T> {
    type: string;
    payload: T;
}

export const sendMessage = (message: MessageEvent<any>) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const receiveMessage = (event: any, dispatchRemoteAction: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const { type: actionType, payload } = event.data;
    if (!actionType) {
        return;
    }
    switch (actionType) {
        case TOKEN_RENEWED:
            const { token } = event.data.payload;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            break;
        case ASSET_TREE_SELECTION_CHANGED:
            dispatchRemoteAction(assetTreeSelectionChanged(payload));
            gaPush({
                event: 'click_asset_tree',
                parameters: {
                    element_name: 'ObjectTree', // name of the element in the WFE
                    component_name: 'AssetTree',
                    flow_name: 'reassign_vehicles',
                    trigger: 'click',
                },
                userProps: {},
            });
            break;
        case IN_MSG_FEATURE_TOGGLE_CHANGED:
            dispatchRemoteAction(
                featureToggleChanged({
                    // @ts-ignore
                    featureKey: payload.key,
                    value: payload.enabled,
                }),
            );
            break;
        default:
            // eslint-disable-next-line
            console.log('Received event in workshop-assignment with type not handled: ', actionType);
            break;
    }
};

export const MessageHandler = React.memo((props) => {
    const dispatch = useAppDispatch();
    const isReadinessNotified = useAppSelector(isReadinessNotifiedSelector);
    useEffect(() => {
        const listenerCallback = (event: any) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);

        if (!isReadinessNotified) {
            sendMessage({
                type: OUT_NOTIFY_READINESS,
                payload: {
                    mfeName: 'workshop-assignment',
                },
            });
            dispatch(readinessNotified());
        }

        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);

    return null;
});
MessageHandler.displayName = 'MessageHandler';

export default MessageHandler;
